<template>
  <div
    :id="[generateDynamicID]"
    :class="[
      { 'ab-sticky-top': isStickyTop, 'ab-sticky-bottom': isStickyBottom },
    ]"
    ref="announcementBarRef"
  >
    <div class="ab-container">
      <!-- Hide and Show bar based on the delay and timer -->
      <div
        v-if="!isHidden && showBar"
        class="ab-flex-container ab-bg-Repeat-Cover"
        :class="[addPatternClass]"
        :style="{ background: barBackground }"
      >
        <div class="ab-flex-item ab-content">
          <!-- Inner HTML content to be rendered -->
          <div v-html="value.configs.content.text"></div>

          <!-- When call acton type is button and related effects and styles -->
          <button
            v-if="isButton"
            :class="{
              'ab-preview-button': isButton,
              'ab-wiggle-effect': value.configs.callToAction.isWiggle,
            }"
            :style="{
              background: value.configs.callToAction.bg,
              color: value.configs.callToAction.fontColor,
            }"
            @click="callAction(value.configs.callToAction)"
          >
            {{ value.configs.callToAction.text }}
          </button>

          <!-- When call action type is link and its related styles -->
          <div v-if="isLink" class="ab-link-action">
            <a
              :style="{
                color: value.configs.callToAction.fontColor,
                'border-bottom': `2px solid ${value.configs.callToAction.fontColor}`,
              }"
              @click="callAction(value.configs.callToAction)"
              >{{ value.configs.callToAction.text }}</a
            >
          </div>
        </div>
        <!-- Show or hide Close icon -->
        <div
          class="ab-flex-item ab-close-icon"
          v-if="value.configs.displayConfig.showCloseIcon"
          @click="hideBar()"
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :style="{ fill: value.configs.displayConfig.iconColor }"
              d="M8.32311 0.874286L7.48484 0L4.16152 3.46614L0.838201 0L-6.10352e-05 0.874286L3.32326 4.34043L-6.10352e-05 7.80657L0.838201 8.68085L4.16152 5.21471L7.48484 8.68085L8.32311 7.80657L4.99978 4.34043L8.32311 0.874286Z"
              fill="#8e2e2e"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Stuck } from "stuck-js";
import { showCTA } from "../../utils/cta.util";

export default {
  name: "announcementBar",
  components: {},
  props: {
    value: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      // value: {
      //   configs: {
      //     callToAction: {
      //       type: "Button",
      //       category: "link",
      //       show: true,
      //       text: "Learn More",
      //       bg: "#F8F8F8",
      //       fontColor: "#4F4F4F",
      //       link: "https://www.google.com",
      //       isWiggle: true,
      //     },
      //     content: {
      //       text: `<h4><span class="ql-size-huge">Black Friday Sale | Upto 50% OFF </span></h4>`,
      //     },
      //     delayTimer: {
      //       show: false,
      //       showBar: 5000,
      //       timer: 3,
      //     },
      //     displayConfig: {
      //       barBackground: "Solid",
      //       backgroundColor: "#7B61FF",
      //       barPosition: "Top",
      //       showCloseIcon: true,
      //       iconColor: "#FFFFFF",
      //       stickyBar: true,
      //       pattern: "Pattern 4",
      //     },
      //     targetPageAndPlatform: {
      //       page: ["All"],
      //       platform: ["All"],
      //     },
      //   },
      // },
      isHidden: false,
      showBar: true,
      timeIntervalRef: {
        showBarOnDelayRef: null,
        showBarAfterTimerRef: null,
      },
    };
  },
  computed: {
    /**
     * Computed method to check if type is button and supposed to show
     */
    isButton() {
      return showCTA("button", this.value.configs.callToAction);
    },
    /**
     * Computed method to check if type is link and supposed to show
     */
    isLink() {
      return showCTA("link", this.value.configs.callToAction);
    },
    /**
     * Computed method to check if Bar background is solid and add respective background color
     */
    barBackground() {
      return this.value.configs.displayConfig.barBackground.toLowerCase() ==
        "solid"
        ? this.value.configs.displayConfig.backgroundColor
        : "";
    },
    /**
     * Computed method to check if Bar background is pattern and generate respective pattern names for binding classes
     */
    addPatternClass() {
      let patternName;
      if (
        this.value.configs.displayConfig.barBackground.toLowerCase() ==
        "pattern"
      ) {
        let patterntype =
          this.value.configs.displayConfig?.pattern.toLowerCase();
        let lastChar = patterntype[patterntype.length - 1];
        patternName = `pattern_${lastChar}`;
      }
      return patternName ? patternName : "no-pattern";
    },
    /**
     * Computed method to check for bar positioning
     */
    topPositioned() {
      return (
        this.value.configs.displayConfig.barPosition.toLowerCase() == "top"
      );
    },
    /**
     * Computed method to check for bar positioning
     */
    bottomPositioned() {
      return (
        this.value.configs.displayConfig.barPosition.toLowerCase() == "bottom"
      );
    },
    isStickyTop() {
      return this.value.configs.displayConfig.stickyBar &&
        this.value.configs.displayConfig.barPosition.toLowerCase() == "top"
        ? true
        : false;
    },
    isStickyBottom() {
      return this.value.configs.displayConfig.stickyBar &&
        this.value.configs.displayConfig.barPosition.toLowerCase() == "bottom"
        ? true
        : false;
    },

    generateDynamicID() {
      return `announcement-bar-ref-${this.index}`;
    },

    // getTheClosestWrapper(){
    //   return this.$refs.announcementBarRef.closest('#ext-announcement-bar-top-view');
    // },
    /**
     * Computed method to check on link performed while clicked on link or button based on the the type
     */
    LinkActionPerfomed() {
      let content = "";
      switch (this.value.configs.callToAction.category) {
        case "link":
          content = this.value.configs.callToAction.link;
          break;
        case "email":
          content = `mailto:${this.value.configs.callToAction.link}`;
          break;
        case "mobile":
          content = `tel:${this.value.configs.callToAction.link}`;
          break;
      }
      return content;
    },
  },
  mounted() {
    this.showBarOnDelay();

    if (this.isStickyTop) {
      new Stuck(
        [{ selector: `#announcement-bar-ref-${this.index}`, wrapper: "#app" }],
        { marginTop: 0 }
      );
    }
  },

  methods: {
    /**
     * Method to determine what should be the call of action based on different types
     * @param {*} cta : Call to Action types and actions
     */
    callAction: (cta) => {
      if (!cta) {
        return;
      }
      switch (cta.category) {
        case "email": {
          if (!cta.email) {
            break;
          }
          window.location.href = `mailto:${cta.email}`;
          break;
        }
        case "phone": {
          if (!cta.phoneNumber) {
            break;
          }
          window.open(`tel://${cta.phoneNumber}`);
          break;
        }
        case "link": {
          if (!cta.link) {
            break;
          }
          window.open(cta.link, "_blank");
          break;
        }
        default: {
          console.error("CTA category not supported");
          break;
        }
      }
    },

    /**
     * Method triggered once close button is clicked
     */
    hideBar() {
      this.isHidden = true;
      this.showBarAfterTimer();
    },

    /**
     * Method to show bar after delay
     */
    showBarOnDelay() {
      if (this.value.configs.delayTimer.show === true) {
        if (this.value.configs.delayTimer.showBar > 0) {
          this.showBar = false;
          this.timeIntervalRef.showBarOnDelayRef = setTimeout(() => {
            this.showBar = true;
          }, this.value.configs.delayTimer.showBar);
        } else {
          this.showBar = true;
        }
      }
    },

    /**
     * Method to show bar after it has been closed after certain time interval
     */
    showBarAfterTimer() {
      if (this.value.configs.delayTimer.show === true) {
        this.timeIntervalRef.showBarAfterTimerRef = setTimeout(() => {
          this.isHidden = false;
        }, Number(this.value.configs.delayTimer.timer * 1000));
      }
    },
  },

  destroyed() {
    clearTimeout(this.timeIntervalRef.showBarOnDelayRef);
    clearTimeout(this.timeIntervalRef.showBarAfterTimerRef);
  },
};
</script>
<style scoped lang="scss">

</style>
